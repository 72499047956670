import styled from "@emotion/styled";
import UtilizeSpacer from "@/styles/theme-styles";
import Link from "next/link";

export const LogoLink = styled(Link)`
  ${UtilizeSpacer({
    options: [
      {
        size: "sm",
        direction: [0, 1],
      },
      {
        size: "2xs",
        direction: [2],
      },
    ],
    type: "padding",
  })}
`;
