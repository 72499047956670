import RenewLogo from "@/assets/images/svgs/RenewLogo";
import * as Styled from "./styles";

export default function LogoLink({ className }: { className?: string }) {
  return (
    <Styled.LogoLink className={className} href="/reports">
      <RenewLogo />
    </Styled.LogoLink>
  );
}
